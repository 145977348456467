import React, { useState, useEffect, useContext, useRef } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import useUser from "../hooks/useUser"
import Widgets from "../helpers/widgets"
import { useDocument } from "../hooks/useDocument"
import { StateContext } from "../helpers/StateProvider"
import ReactToPrint from "react-to-print"
import { Share } from "../components/LisitngDetails/ListingDetails.styles"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../assets/icons/facebook-share.svg"
import Twitter from "../assets/icons/twitter-share.svg"
import Linkedin from "../assets/icons/linkedin-share.svg"
import Whatsapp from "../assets/icons/whatsapp-icon.svg"
import telegram from "../assets/icons/telegram_logo_circle_icon.svg"
import email from "../assets/icons/email-envelop.svg"
const IndexPage = props => {
  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [arabic, setArabic] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [pageWidgets])
  const { language, setLanguage } = useContext(StateContext)
  const componentRef = useRef()
  const currentUrl = window.location.href
  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="blog" />
            <div className="center_cards" ref={componentRef}>
              <div className="cards">
                {language == false
                  ? "DOT VIP Blog has been designed to provide people with information they want. That is why you are going to find a mix of real estate tutorials, how to use this website and its smartphone applications, Housing Trends, Local Events, Home Maintenance ideas, Relocation Information, Property Lists, Home Décor and many more. Just click the link https://estatetutorial.blogspot.com/ to visit our blog. Your ideas and recommendations are more than welcome through our email Info@onlinepropertydeals.com or through your comments within the blog."
                  : "تم تصميم مدونة DOT VIP لتزويد الأشخاص بالمعلومات التي يريدونها. هذا هو السبب في أنك ستجد مزيجًا من دروس تتعلق بالعقارات، وكيفية استخدام هذا الموقع وتطبيقات الهواتف الذكية الخاصة به، واتجاهات الإسكان، والأحداث المحلية ، وأفكار صيانة المنزل ، ومعلومات حول نقل السكن من المساحة السكنيّة إلى أخرى، وقوائم العقارات المميزة ، والديكور المنزلي وغيرها الكثير. فقط انقر فوق الرابط https://estatetutorial.blogspot.com/ لزيارة مدونتنا. نرحب بأفكارك وتوصياتك من خلال البريد الإلكتروني Info@onlinepropertydeals.com أو من خلال تعليقاتكم ضمن المدونة."}
                <br></br>
                <a href="https://estatetutorial.blogspot.com/">
                  https://estatetutorial.blogspot.com/
                </a>
              </div>
              <Share>
                <div className="text">
                  {arabic == false ? "Share on" : "مشاركه فى"}
                </div>
                <div className="socials">
                  <FacebookShareButton
                    url={currentUrl}
                    title="Facebook"
                    hashtag="#property"
                  >
                    <img src={Faceboook} alt="facebook icon" />
                  </FacebookShareButton>
                  <TwitterShareButton url={currentUrl} title="Twitter">
                    <img src={Twitter} alt="twitter icon" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={currentUrl} title="Linkedin">
                    <img src={Linkedin} alt="linkedin icon" />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={currentUrl} title="Whatsapp">
                    <img src={Whatsapp} alt="whatsapp icon" />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currentUrl} title="telegram">
                    <img
                      src={telegram}
                      alt="whatsapp icon"
                      style={{ width: "64px" }}
                    />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={currentUrl}
                    subject="share page"
                    body={"this is link shared"}
                  >
                    <img
                      src={email}
                      alt="whatsapp icon"
                      style={{ width: "50px" }}
                    />
                  </EmailShareButton>
                </div>
              </Share>
            </div>
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button className="btn btn-primary">Print to PDF!</button>
              )}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
